<template>
  <v-container class="fill-height mt-md-4">
    <!-- <WidgetAlert /> -->
    <v-row class="text-center" justify="center">
      <v-col cols="12" class="mb-2" align-self="start">
        <h2 class="header font-weight-bold my-2 white--text">
          PASBUS
        </h2>
        <h4 class="subheader font-weight-bold white--text">
          EL BUS FLEXIBLE
        </h4>

        <v-spacer></v-spacer>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6">
        <h4 class="white--text">NOU SISTEMA DE MOBILITAT SOSTENIBLE</h4>
        <p class="white--text">
          PASBUS és un nou sistema de transport col·lectiu amb el qual
          l'usuari/a tria l'origen, el destí, la freqüència horària i la parada
          oficial on vol que sigui recollit/da. Per donar un servei global,
          personalitzat, ecològic i econòmic.
        </p>

        <p class="white--text">
          Una vegada sàpigues el destí i l'hora que que ha d'estar allà,
          selecciona la seva freqüència, la parada d'orígen (la més propera del
          teu punt d'orígen) i la parada destí (la més propera al teu punt de
          destinació).
        </p>

        <div class="white--text">
          <h4>COM FUNCIONA?</h4>
          <p>
            <!-- Cal omplir el formulari correctament i enviar-lo -->
            Es pot fer la sol.licitud per telèfon, SMS o email. Pròximament
            també des de la webApp.<br />
            <strong>Màxim fins les 18h del dia abans.</strong>
          </p>
          <h5 class="mt-2">INDICANT:</h5>
          <ul>
            <li>Nom de la persona</li>
            <!-- <li>Número DNI<sup>*</sup></li> -->
            <li>Número de Telèfon</li>
            <li>Orígen (parada a triar de la bossa)</li>
            <li>Destí (parada a triar de la bossa)</li>
            <li>Data (dia desitjat)</li>
            <li>Hora en destí</li>
            <li>Motivació (Metge, treball, gestions, ...)</li>
            <li>Número de Places</li>
          </ul>
          <!-- <small><sup>*</sup>Només la primera vegada.</small> -->
          <h5 class="mt-2">HORARI:</h5>
          <p>
            <strong>
              De dilluns a divendres de 9:00 a 19:00h. <br />
              Dissabtes de 10:00 a 12:00h.
            </strong>
          </p>
          <h4>PLACES LIMITADES !!!</h4>
        </div>
      </v-col>

      <v-col cols="12" md="6">
        <v-card class="mx-auto px-2 mb-2">
          <v-card-subtitle class="title primary--text mb-0 pb-0">
            SOL.LICITUD PASBUS
          </v-card-subtitle>
          <v-list two-line class="mt-0">
            <v-list-item link @click="phoneTo">
              <v-list-item-icon>
                <v-icon color="gray">
                  mdi-phone
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title class="font-weight-bold"
                  >977 660 821</v-list-item-title
                >
                <v-list-item-subtitle>Telèfon Pasbus</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item link @click="smsTo">
              <v-list-item-icon>
                <v-icon color="gray">
                  fas fa-sms
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title class="font-weight-bold"
                  >699 913 586</v-list-item-title
                >
                <v-list-item-subtitle>SMS Pasbus</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item link @click="mailto('pasbus1@pndssm.org')">
              <v-list-item-icon>
                <v-icon color="gray">
                  mdi-email
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>pasbus1@pndssm.org</v-list-item-title>
                <v-list-item-subtitle>Pasbus</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
        <PasBusForm v-if="userLogged && showPasbusForm" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" align-self="end">
        <v-img
          :src="require('../assets/img/logo-autocars-del-penedes-petit.png')"
          class="mt-5"
          contain
          height="80"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PasBusForm from "@/components/PasBusForm";

export default {
  name: "Pasbus",

  data: () => ({
    // logged: false
    // showPasbusForm: false
  }),

  computed: {
    userLogged() {
      return this.$store.getters.loggedIn;
    },
    showPasbusForm() {
      return this.$store.getters.getUserPrefs.pasbusForm;
      // return false;
    }
  },

  methods: {
    phoneTo() {
      window.location.href = "tel:+34977660821";
    },
    mailto(email) {
      window.location.href = "mailto:" + email;
    },
    smsTo() {
      window.location.href = "sms:+34699913586";
    }
    // userPrefs() {
    //   const userPrefs = this.$store.getters.getUserPrefs;
    //   return userPrefs.pasbusForm;
    // }
  },

  components: {
    PasBusForm
    // WidgetAlert
  }
};
</script>
