<template>
  <div>
    <v-alert type="error" v-if="showError" class="my-2">
      Woops! S'ha produït un error :-(
    </v-alert>
    <v-alert type="warning" v-if="showWarning" class="my-2">
      La teva sol.licitud no s'ha pogut registrar. Disculpa les molèsties.
    </v-alert>
    <v-alert type="success" v-if="showSuccess" class="my-2">
      <h4>La teva sol.licitud Pasbus s'ha enviat correctament.</h4>
      <p class="mb-0">
        En breu ens posarem en contacte amb tu per a confirmar-ho.
      </p>
    </v-alert>

    <v-card>
      <v-toolbar color="red darken-2" cards dark flat>
        <v-card-title class="title">
          NOVA SOL.LICITUD PASBUS
        </v-card-title>
      </v-toolbar>
      <v-form ref="form" v-model="valid" @submit.prevent="submit" class="pa-4">
        <v-row class="pt-2">
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              v-model.trim="form.name"
              :rules="[rules.required]"
              label="Nom"
              maxlength="20"
              required
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              v-model.trim="form.surnames"
              :rules="[rules.required]"
              label="Cognoms"
              maxlength="50"
              required
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              v-model.trim="form.phone"
              :rules="phoneRules"
              label="Telèfon"
              maxlength="9"
              required
              dense
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="form.email"
              :rules="emailRules"
              label="E-mail"
              autocomplete="email"
              required
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-select
              v-model="origen"
              :items="pasbusLines"
              item-text="line"
              item-value="id"
              label="Línia"
              v-on:change="setPasbusOrigens(origen.id)"
              return-object
              :rules="selectRules"
              required
              read-only
              dense
            ></v-select>
          </v-col>

          <v-col cols="12">
            <v-select
              v-if="origen !== null"
              v-model="form.paradaOrigenId"
              :items="paradesOrigen"
              :item-text="item => item.stop + ' - ' + item.address"
              item-value="id"
              label="Parada Orígen"
              hint="Selecciona primer la línia"
              :loading="itemsLoading"
              :rules="selectRules"
              v-on:change="setPasbusDestins()"
              required
              read-only
              dense
            ></v-select>
            <v-select
              v-if="form.paradaOrigenId"
              v-model="form.paradaDestiId"
              :items="paradesDesti"
              :item-text="item => item.stop + ' - ' + item.address"
              item-value="id"
              label="Parada Destí"
              hint="Selecciona primer la parada origen"
              :loading="itemsLoading"
              :rules="selectRules"
              required
              read-only
              dense
              class="mt-5"
            ></v-select>
          </v-col>
          <v-col cols="12" md="4">
            <v-menu
              ref="timeMenu"
              v-model="time"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="form.arrivaltime"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="form.arrivaltime"
                  label="Hora en Destí"
                  prepend-icon="mdi-clock-time-four-outline"
                  required
                  :rules="[rules.required]"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="time"
                v-model="form.arrivaltime"
                format="24hr"
                @click:minute="$refs.timeMenu.save(form.arrivaltime)"
              ></v-time-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="4">
            <v-menu
              ref="dateMenu"
              v-model="dateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="form.date"
              transition="scale-transition"
              offset-y
              max-width="350px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="formatDate"
                  label="Data"
                  prepend-icon="mdi-calendar"
                  required
                  :rules="[rules.required]"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="form.date"
                no-title
                scrollable
                locale="ca-es"
                first-day-of-week="1"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="dateMenu = false">
                  Cancel.lar
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dateMenu.save(form.date)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="form.passengers"
              label="Número de Places"
              :rules="numPlacesRules"
              autocomplete="places"
              type="number"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-select
              v-if="reasonTrip.length > 0"
              v-model="form.reasontrip"
              :items="reasonTrip"
              item-text="reason"
              item-value="id"
              label="Motiu"
              :loading="itemsReasonLoading"
              :rules="selectRules"
              required="true"
              read-only
            ></v-select>
            <v-textarea
              v-else
              v-model="form.reasontrip"
              label="Motivació"
              :rules="[rules.required]"
              autocomplete="motivacio"
              placeholder="Ex: Metge, treball, gestions, ..."
              persistent-placeholder
              hint="Quin és el motiu del desplaçament?"
              persistent-hint
              required
              auto-grow
              outlined
              rows="3"
              row-height="25"
            ></v-textarea>
          </v-col>
          <v-col cols="12">
            <v-textarea
              v-model="form.note"
              label="Notes"
              autocomplete="notes"
              placeholder="Notes"
              hint="Alguna observació?"
              auto-grow
              outlined
              rows="3"
              row-height="25"
            ></v-textarea>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" md="6">
            <v-checkbox
              v-model="form.pmr"
              input-value="0"
              label="Persona amb Mobilitat Reduïda"
              class="mt-0 mb-2"
            >
            </v-checkbox>
          </v-col>
          <v-col class="d-flex ml-auto" cols="12" sm="3" xsm="12">
            <v-btn
              large
              block
              :disabled="valid === false"
              color="success"
              @click.stop="showModalCheckSolicitud"
              >Enviar
            </v-btn>
          </v-col>
        </v-row>

        <!-- Showing Info before submit -->
        <v-dialog v-if="valid" v-model="modalCheckSolicitud" width="550">
          <v-card>
            <v-card-title class="heading-2 success white--text">
              DADES SOL.LICITUD PASBUS
              <p class="body-1" style="word-break: keep-all;">
                Si us plau, comprova les dades abans d'enviar la sol.licitud.
              </p>
            </v-card-title>

            <v-card flat class="mx-auto px-3" tile>
              <v-container>
                <v-row dense>
                  <v-col>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle>Nom</v-list-item-subtitle>
                        {{ form.name }} {{ form.surnames }}
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle>Telèfon</v-list-item-subtitle>
                        {{ form.phone }}
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle>E-mail</v-list-item-subtitle>
                        {{ form.email }}
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle>Orígen</v-list-item-subtitle>
                        <div class="rounded">
                          <p
                            v-if="form.paradaOrigenId"
                            v-html="getNomParadaOrigen()"
                          ></p>
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle>Destí</v-list-item-subtitle>
                        <div class="rounded">
                          <p
                            v-if="form.paradaDestiId"
                            v-html="getNomParadaDesti()"
                          ></p>
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle>Data</v-list-item-subtitle>
                        {{ formatDateEs() }}
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle
                          >Hora en Destí</v-list-item-subtitle
                        >
                        {{ form.arrivaltime }}
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle
                          >Número de places</v-list-item-subtitle
                        >
                        {{ form.passengers }}
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col>
                    <v-list-item three-line v-if="form.reasontrip">
                      <v-list-item-content>
                        <v-list-item-subtitle>Motivació</v-list-item-subtitle>
                        {{ getNomReasonTrip() }}
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col>
                    <v-list-item three-line v-if="form.pmr">
                      <v-list-item-content>
                        <v-list-item-subtitle
                          >Persona amb mobilitat reduïda</v-list-item-subtitle
                        >
                        {{ getNomPmr() }}
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col>
                    <v-list-item three-line v-if="form.note">
                      <v-list-item-content>
                        <v-list-item-subtitle>Notes</v-list-item-subtitle>
                        {{ form.note }}
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                outlined
                @click="modalCheckSolicitud = false"
              >
                Cancel.lar
              </v-btn>
              <v-btn
                color="success"
                :disabled="sendDisabled"
                @click.stop="submit()"
              >
                Enviar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { parseISO } from "date-fns";
export default {
  name: "pasbus-form",

  data: () => ({
    userToken: null,
    user: null,
    dialog: true,
    valid: false,
    showError: false,
    showSuccess: false,
    showWarning: false,
    errorCities: null,
    errorReasons: null,
    errorForm: null,
    modalCheckSolicitud: false,

    cities: [],
    origen: null,
    desti: null,
    pasbusLines: [],
    nomParadaOrigen: "",
    nomParadaDesti: "",
    paradesOrigen: [],
    paradesDesti: [],
    reasonTrip: [],
    dateMenu: false,
    time: null,
    date: null,

    itemsLoading: false,
    itemsReasonLoading: false,

    loading: false,

    form: {
      name: "",
      surnames: "",
      phone: "",
      email: "",
      date: new Date().toISOString().substr(0, 10),
      arrivaltime: null,
      reasontrip: null,
      paradaOrigenId: null,
      paradaDestiId: null,
      passengers: 1,
      pmr: 0,
      note: ""
    },
    emailRules: [
      v => !!v || "Requerit",
      v => /.+@.+\..+/.test(v) || "Ha de ser un e-mail vàlid"
    ],
    phoneRules: [
      v => !!v || "Requerit",
      v => /^[0-9]{9}?$/i.test(v) || "El telèfon ha de ser vàlid"
    ],
    // nifRules: [
    //   v => !!v || "Requerit",
    //   v =>
    //     /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKE]$/i.test(v) ||
    //     "Ha de ser un NIF vàlid"
    // ],
    selectRules: [v => !!v || "Selecciona una opció"],
    numPlacesRules: [
      v => !!v || "Requerit",
      v => /^[0-5]{1}?$/i.test(v) || " Màxim 5 places"
    ],
    show1: false,
    rules: {
      required: value => !!value || "Requerit."
      // min: v => (v && v.length >= 8) || "Min 8 characters"
    },
    formReceived: false,
    sendDisabled: false
  }),

  computed: {
    getAllcities() {
      return this.$store.getters.getCities;
    },
    getUserInfo() {
      return this.$store.getters.getUserInfo;
    },
    formatDate() {
      return this.formatDateEs();
    }
    // pasbusLines() {
    //   return this.$store.getters.getPasbusLines;
    // }
  },

  created() {
    this.setCities();
  },

  beforeMount() {
    this.user = this.getUserInfo;
    this.setUser();
    this.setReasonTrip();
    this.setPasbusLines();
  },
  // mounted() {
  //   this.setPasbusLines();
  // },

  methods: {
    validate() {
      this.$refs.form.validate();
    },

    reset() {
      this.$refs.form.reset();
    },

    resetValidation() {
      this.$refs.form.resetValidation();
    },

    setUser() {
      this.form.name = this.user.name;
      this.form.surnames = this.user.surnames;
      this.form.email = this.user.email;
      this.form.phone = this.user.phone;
    },

    setCities() {
      this.$store
        .dispatch("setCities")
        .then(response => {
          // console.log("Cities: " + response.data.length);
          this.cities = response.data;
        })
        .catch(error => {
          this.errorCities = error;
        });
    },

    async setPasbusLines() {
      const user = {
        token: this.$store.state.token,
        user_id: this.user.userId
      };
      console.log("Getting Pasbus Lines");
      await this.$store
        .dispatch("setPasbusLines", user)
        .then(response => {
          console.log("PasbusLines: " + JSON.stringify(response.data));
          this.pasbusLines = response.data;
        })
        .catch(error => {
          this.errorCities = error;
        });
    },

    formatDateEs() {
      // Filtre global amb date-fns (vue-date-fns) definit a main.js
      return this.$dateTimeEUformat(parseISO(this.form.date), "dd-MM-yyyy");
    },

    async setPasbusOrigens(lineId) {
      this.itemsLoading = true;
      const payload = {
        token: this.$store.state.token,
        user_id: this.user.userId,
        line_id: lineId
      };
      await this.$store
        .dispatch("setPasbusOrigens", payload)
        .then(response => {
          // console.log("BusStopsOrigen: " + response.data.origen.length);
          this.paradesOrigen = response.data;
          // this.paradesDesti = response.data;
          this.itemsLoading = false;
        })
        .catch(error => {
          this.errorCities = error;
        });
    },

    setPasbusDestins() {
      let destins = this.paradesOrigen.filter(
        parada => parada.id !== this.form.paradaOrigenId
      );
      this.paradesDesti = destins;
    },
    // async setPasbusDestins(lineId) {
    //   this.itemsLoading = true;
    //   const payload = {
    //     token: this.$store.state.token,
    //     user_id: this.user.userId,
    //     line_id: lineId
    //   };
    //   await this.$store
    //     .dispatch("setPasbusDestins", payload)
    //     .then(response => {
    //       // console.log("BusStopsDesti: " + response.data.desti.length);
    //       this.paradesDesti = response.data;
    //       this.itemsLoading = false;
    //     })
    //     .catch(error => {
    //       this.errorCities = error;
    //     });
    // },

    async setReasonTrip() {
      this.itemsReasonLoading = true;
      const user = {
        token: this.$store.state.token,
        user_id: this.user.userId
      };
      await this.$store
        .dispatch("setPasbusReasonTrip", user)
        .then(response => {
          // console.log("BusStopsDesti: " + response.data.desti.length);
          this.reasonTrip = response.data;
          this.itemsReasonLoading = false;
        })
        .catch(error => {
          this.errorReasons = error;
        });
    },

    async submit() {
      this.loading = true;
      this.sendDisabled = true;
      const userCredentials = {
        userId: this.user.userId,
        userToken: localStorage.getItem("access_token")
      };
      let pasbusRequest = {
        user_id: parseInt(userCredentials.userId, 10),
        token: userCredentials.userToken,
        date: this.form.date,
        source_id: this.form.paradaOrigenId,
        destination_id: this.form.paradaDestiId,
        arrivaltime: this.form.arrivaltime,
        passengers: parseInt(this.form.passengers, 10),
        pmr: this.form.pmr * 1, // convert true/flase to 0/1 by multiply by 1
        reasontrip: parseInt(this.form.reasontrip, 10),
        note: this.form.note
      };
      // console.log("userInfo:" + JSON.stringify(userCredentials));
      await this.$store
        .dispatch("setPasbusRequest", pasbusRequest)
        .then(response => {
          console.log("Solicitud rebuda: " + response.data);
          this.formResponse = response.data;
          if (response.data) {
            this.showSuccess = true;
            this.loading = false;
            this.modalCheckSolicitud = false;
            this.reset();
          } else {
            this.formResponse = 0;
            this.showWarning = true;
            this.loading = false;
            this.modalCheckSolicitud = false;
          }
          this.sendDisabled = false;
        })
        .catch(error => {
          this.formResponse = 0;
          this.errorForm = error;
          this.loading = false;
          this.sendDisabled = false;
        });
    },

    showModalCheckSolicitud() {
      let isValid = this.$refs.form.validate();
      if (!isValid) {
        this.modalCheckSolicitud = false;
      } else {
        this.modalCheckSolicitud = true;
      }
    },

    getNomParadaOrigen() {
      let nom = this.paradesOrigen.filter(
        el => el.id === this.form.paradaOrigenId
      );
      return nom[0].stop + "-" + nom[0].address;
    },

    getNomParadaDesti() {
      let nom = this.paradesDesti.filter(
        el => el.id === this.form.paradaDestiId
      );
      return nom[0].stop + "-" + nom[0].address;
    },

    getNomReasonTrip() {
      let nom = this.reasonTrip.filter(el => el.id === this.form.reasontrip);
      return nom[0].reason;
    },

    getNomPmr() {
      if (this.form.pmr) {
        return "Sí";
      } else {
        return "No";
      }
    }
  }
};
</script>
